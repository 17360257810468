@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Roboto:wght@100;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

@font-face {
  font-family: 'SF Pro Display Medium';
  src: url('/src/scss/fonts/sfProDisplayRegular/SFProDisplay-Medium.eot');
  src: url('/src/scss/fonts/sfProDisplayRegular/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
  url('/src/scss/fonts/sfProDisplayRegular/SFProDisplay-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (max-width: 899px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

$c_g: #CBAD73;
$c_b: #020202;
$c_o: #344767;
$c_w: #FFFFFF;
$c_gray: #3447678A;
$c_beige: #E1E1E1;
$c_lg: #E5E5E5;
$c_gray1: #717171;
$c_gray2: #DFDFDF;
$c_r: #B51111;
$c_dis: #202223;


.contentStyle {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  outline: none;
  background: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px !important;
  max-Width: 500px;
  width: 100%;

  @media (max-width: 599px) {
    max-width: 300px;
    min-width: 260px;
    margin-top: 300px;
    bottom: 15%;
  }
}

.overlayStyle {
  position: fixed;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  color: rgba(0, 0, 0, 0.8) !important;
  background-color: rgba(0, 0, 0, 0.47);

  @media (max-width: 599px) {
    overflow: auto;
  }
}

/* montserrat-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  src: url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.eot"); /* IE9 Compat Modes */
  src:
          url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.eot?#iefix") format("embedded-opentype"),
            /* IE6-IE8 */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.woff2") format("woff2"),
            /* Super Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.woff") format("woff"),
            /* Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.ttf") format("truetype"),
            /* Safari, Android, iOS */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.svg#Montserrat")
          format("svg"); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.eot"); /* IE9 Compat Modes */
  src:
          url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.eot?#iefix") format("embedded-opentype"),
            /* IE6-IE8 */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.woff2") format("woff2"),
            /* Super Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.woff")
          format("woff"),
            /* Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.ttf") format("truetype"),
            /* Safari, Android, iOS */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.svg#Montserrat")
          format("svg"); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.eot"); /* IE9 Compat Modes */
  src:
          url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.eot?#iefix") format("embedded-opentype"),
            /* IE6-IE8 */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.woff2") format("woff2"),
            /* Super Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.woff") format("woff"),
            /* Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.ttf") format("truetype"),
            /* Safari, Android, iOS */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.svg#Montserrat")
          format("svg"); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.eot"); /* IE9 Compat Modes */
  src:
          url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.eot?#iefix") format("embedded-opentype"),
            /* IE6-IE8 */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.woff2") format("woff2"),
            /* Super Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.woff") format("woff"),
            /* Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.ttf") format("truetype"),
            /* Safari, Android, iOS */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.svg#Montserrat")
          format("svg"); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.eot"); /* IE9 Compat Modes */
  src:
          url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.eot?#iefix") format("embedded-opentype"),
            /* IE6-IE8 */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.woff2") format("woff2"),
            /* Super Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.woff") format("woff"),
            /* Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.ttf") format("truetype"),
            /* Safari, Android, iOS */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.svg#Montserrat")
          format("svg"); /* Legacy iOS */
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
